<template>
  <div>
    <div>
      <b-card-actions title="Filters" action-collapse>
        <div>
          <b-row class="mb-1">
            <b-col md="8" lg="8" xs="12">
              <b-form-input
                placeholder="Search By Milestone Title"
                type="text"
                class="d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font"
                v-on:keyup="onSearch"
                v-model.lazy="searchTerm"
              />
            </b-col>
            <b-col md="4" lg="4" xs="12">
              <flat-pickr
                  v-model="filterRangeDate"
                  v-on:input="loadItems"
                  class="form-control"
                  placeholder="Enter Deadline Range"
                  :config="{ mode: 'range'}"
              />
            </b-col>

          </b-row>
        </div>
        <div>
          <b-row>
            <b-col md="6" lg="4" xs="12">
              <v-select
                v-model="filterPaymentType"
                :options="paymentTypeConstants"
                :reduce="(item) => item.value"
                v-on:input="filterByPaymentType"
                label="name"
                placeholder="By Payment Type"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
            <b-col md="6" lg="4" xs="12">
              <v-select
                v-model="filterMilestoneType"
                :options="milestoneTypeConstants"
                :reduce="(item) => item.value"
                v-on:input="filterByMilestoneType"
                label="name"
                placeholder="By Milestone Type"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>

            <b-col md="6" lg="4" xs="12">
              <v-select
                v-model="filterMilestoneStatus"
                :options="milestoneStatusConstants"
                :reduce="(item) => item.value"
                v-on:input="filterByMilestoneStatus"
                label="name"
                placeholder=" By Milestone Status"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
          </b-row>
        </div>
      </b-card-actions>
    </div>

    <div>
      <b-card>
        <div>
          <!-- search input -->
          <div
            class="custom-search d-flex align-items-center justify-content-end"
          >
            <div
              class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
            >
              <template
                v-if="$permissionAbility(MILESTONE_FORWARD_ACCESS, permissions)"
              >
                <b-button
                  class="flex-shrink-0 mr-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-primary"
                  v-on:click="showMilestoneForwardModal"
                >
                  Forward Payment
                </b-button>
              </template>
            </div>
          </div>

          <!-- table -->
          <vue-good-table
            :rows="rows"
            :columns="columns"
            styleClass="vgt-table table-custom-style striped"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :sort-options="{
              enabled: false,
              multipleColumns: true,
              initialSortBy: [{ field: 'date', type: 'asc' }],
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <template v-if="props?.column?.field === 'format_title'">
                <b-link
                  v-if="props?.row?.status == 'paid'"
                  v-on:click="onTaskBoardPage(props?.row?.id)"
                >
                  {{ props?.row?.title }}
                  <b-badge pill variant="light-primary">{{
                    formatMilestoneType(props?.row?.is_extra)
                  }}</b-badge>
                </b-link>

                <span v-else
                  >{{ props?.row?.title }}
                  <b-badge pill variant="light-primary">{{
                    formatMilestoneType(props?.row?.is_extra)
                  }}</b-badge></span
                >
                <div v-if="props?.row?.payment_type" class="mt-1 mb-1">
                  Payment Type:
                  <b-badge pill variant="light-primary">{{
                    formatType(props?.row?.payment_type)
                  }}</b-badge>
                </div>
                <div v-if="props?.row?.payment_type" class="mt-1 mb-1">
                  Created On:
                  <span class="font-weight-bold">{{ props?.row?.created_at }}</span>
                </div>
                <!-- created_at -->
              </template>

              <template v-if="props?.column?.field === 'format_currency'">
                <FormatCurrency :type="'currency'" :currency="props?.row?.currency" />
              </template>

              <template v-if="props?.column?.field === 'format_amount'">
                <div class="table-cell-margin">
                  Amount:
                  <b><FormatCurrency :amount="props?.row?.total_amount" :currency="props?.row?.currency" /></b>

                </div>
                <div class="table-cell-margin">
                  VAT:
                  <b><FormatCurrency :amount="props?.row?.total_vat_amount" :currency="props?.row?.currency" /></b>
                </div>
                <div class="table-cell-margin">
                  TAX:
                  <b><FormatCurrency :amount="props?.row?.total_tax_amount" :currency="props?.row?.currency" /></b>
                </div>
                <div class="table-cell-margin">
                  Total Amount:
                  <b><FormatCurrency :amount="props?.row?.grand_amount" :currency="props?.row?.currency" /></b>
                </div>
                <template>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-sm"
                      v-on:click="showTransactionHistory(props.row)"
                  >
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                </template>
              </template>
              <template v-if="props?.column?.field === 'format_initial_amount'">
                <div class="table-cell-margin">
                  Total Amount:
                  <b><FormatCurrency :amount="props?.row?.initial_total_amount" :currency="props?.row?.currency" /></b>
                </div>
                <div class="table-cell-margin">
                  Total VAT:
                  <b><FormatCurrency :amount="props?.row?.initial_total_vat_amount" :currency="props?.row?.currency" /></b>
                </div>
                <div class="table-cell-margin">
                  Total TAX:
                  <b><FormatCurrency :amount="props?.row?.initial_total_tax_amount" :currency="props?.row?.currency" /></b>
                </div>
                <div class="table-cell-margin">
                  Grand Total Amount:
                  <b><FormatCurrency :amount="props?.row?.initial_grand_amount" :currency="props?.row?.currency" /></b>
                </div>
              </template>


              <!-- <template v-if="props?.column?.field === 'format_paid_amount'">
                <div class="table-cell-margin">
                  <b>Total Amount: </b>
                  <template
                    v-if="
                      props?.row?.initial_total_amount >=
                      props?.row?.total_amount
                    "
                  >
                    <span>
                      {{
                        props?.row?.initial_total_amount -
                        props?.row?.total_amount
                      }}</span
                    >
                  </template>
                  <template
                    v-else-if="
                      props?.row?.initial_total_amount <
                      props?.row?.total_amount
                    "
                  >
                    <span>
                      {{
                        props?.row?.total_amount -
                        props?.row?.initial_total_amount
                      }}</span
                    >
                  </template>
                </div>
                <div class="table-cell-margin">
                  <b>Total VAT: </b>
                  <template
                    v-if="
                      props?.row?.initial_total_vat_amount >=
                      props?.row?.total_vat_amount
                    "
                  >
                    <span>
                      {{
                        props?.row?.initial_total_vat_amount -
                        props?.row?.total_vat_amount
                      }}</span
                    >
                  </template>
                  <template
                    v-else-if="
                      props?.row?.initial_total_vat_amount <
                      props?.row?.total_vat_amount
                    "
                  >
                    <span>
                      {{
                        props?.row?.total_vat_amount -
                        props?.row?.initial_total_vat_amount
                      }}</span
                    >
                  </template>
                </div>
                <div class="table-cell-margin">
                  <b>Total TAX: </b>

                  <template
                    v-if="
                      props?.row?.initial_total_tax_amount >=
                      props?.row?.total_tax_amount
                    "
                  >
                    <span>
                      {{
                        props?.row?.initial_total_tax_amount -
                        props?.row?.total_tax_amount
                      }}</span
                    >
                  </template>
                  <template
                    v-else-if="
                      props?.row?.initial_total_tax_amount <
                      props?.row?.total_tax_amount
                    "
                  >
                    <span>
                      {{
                        props?.row?.total_tax_amount -
                        props?.row?.initial_total_tax_amount
                      }}</span
                    >
                  </template>
                </div>
                <div class="table-cell-margin">
                  <b>Grand Total Amount: </b>
                  <template
                    v-if="
                      props?.row?.initial_grand_amount >=
                      props?.row?.grand_amount
                    "
                  >
                    <span>
                      {{
                        props?.row?.initial_grand_amount -
                        props?.row?.grand_amount
                      }}</span
                    >
                  </template>
                  <template
                    v-else-if="
                      props?.row?.initial_grand_amount <
                      props?.row?.grand_amount
                    "
                  >
                    <span>
                      {{
                        props?.row?.grand_amount -
                        props?.row?.initial_grand_amount
                      }}</span
                    >
                  </template>
                </div>

                <div class="table-cell-margin">
                  <b>Currency: </b>
                  <span>{{ props?.row?.currency }}</span>
                </div>
              </template> -->

              <template v-if="props?.column?.field === 'format_payment_type'">
                <template v-if="props?.row?.payment_type">
                  <b-badge pill variant="light-primary">{{
                    formatType(props?.row?.payment_type)
                  }}</b-badge>
                </template>
              </template>

              <template v-if="props?.column?.field === 'format_payment_deadline'">
                <template v-if="props?.row?.payment_deadline">
                  <b-badge pill variant="light-success">{{
                      props?.row?.payment_deadline
                  }}</b-badge>
                </template>
                <template v-else>
                  <b-badge pill variant="light-danger">Not Set</b-badge>
                </template>
              </template>

              <template v-if="props?.column?.field === 'format_invoice_info'">
                <template v-if="props?.row?.invoice_created_date">
                  <template v-if="props?.row?.invoice_no">
                    <b-link v-on:click="onShowPage(props.row.id)">
                      <b-badge pill variant="light-primary">{{
                        props?.row?.invoice_no
                      }}</b-badge>
                    </b-link>
                  </template>
                  <template v-if="props?.row?.invoice_created_date">
                    <div class="table-cell-margin">
                      <b>Created On: </b>
                      <span>
                        {{
                          formatFnTableCreatedDate(
                            props?.row?.invoice_created_date
                          )
                        }}</span
                      >
                    </div>
                  </template>
                </template>
                <template v-else>
                  <b-badge pill variant="light-danger"
                    >Not Generated</b-badge
                  ></template
                >
              </template>

              <template v-if="props?.column?.field === 'format_status'">
                <template v-if="props?.row?.status == 'paid'">
                  <b-badge pill variant="light-success">Paid</b-badge>
                </template>
                <template v-else-if="props?.row?.status == 'partial_paid'">
                  <b-badge pill variant="light-info">Partially Paid</b-badge>
                </template>
                <template v-else-if="props?.row?.status == 'due'">
                  <div>
                    <b-badge pill variant="light-danger">Due</b-badge>
                  </div>
                </template>
                <template v-else-if="props?.row?.status == 'forward'">
                  <b-badge pill variant="light-warning">All Forwarded</b-badge>
                </template>
                <br />
                <template v-if="props?.row?.milestoneTaskBoard?.data">
                  <b-badge pill variant="light-primary" class="mt-1"
                    >Task Board Created
                  </b-badge>
                </template>



                <template v-if="props?.row?.milestoneTaskBoard?.data">
                  <br/>
                  <b-badge pill :variant="setStatusVariant(props?.row?.milestoneTaskBoard?.data?.status)" class="mt-1"
                  >Task Board {{ setStatus(props?.row?.milestoneTaskBoard?.data?.status) }}
                  </b-badge>
                </template>



              </template>

              <template v-if="props?.column?.field === 'format_forward_from'">
                <template>
                  <template
                    v-if="
                      props?.row?.milestoneForwardFromHistories?.data.length > 0
                    "
                  >
                    <div
                      v-for="(item, index) in props?.row
                        ?.milestoneForwardFromHistories?.data"
                      :key="index"
                      class="table-cell-margin"
                    >
                      <template v-if="item?.forward_status == 'forward_all'">
                        <b-badge pill variant="light-primary">{{
                          `All Forwarded From: ${item?.forwardFrom?.data?.title}`
                        }}</b-badge></template
                      >

                      <template v-if="item?.forward_status == 'forward_vat'">
                        <b-badge pill variant="light-primary">{{
                          `VAT Forwarded From: ${item?.forwardFrom?.data?.title}`
                        }}</b-badge></template
                      >
                      <template v-if="item?.forward_status == 'forward_tax'">
                        <b-badge pill variant="light-primary">{{
                          `TAX Forwarded From: ${item?.forwardFrom?.data?.title}`
                        }}</b-badge></template
                      >
                      <template v-if="item?.forward_status == 'forward_amount'">
                        <b-badge pill variant="light-primary">{{
                          `Amount Forwarded From: ${item?.forwardFrom?.data?.title}`
                        }}</b-badge></template
                      >
                    </div>
                  </template>
                  <template v-else>
                    <b-badge pill variant="light-danger"
                      >No Action Done</b-badge
                    >
                  </template>
                </template>
              </template>
              <template v-if="props?.column?.field === 'format_forward_to'">
                <template
                  v-if="
                    props?.row?.milestoneForwardToHistories?.data.length > 0
                  "
                >
                  <div
                    v-for="(item, index) in props?.row
                      ?.milestoneForwardToHistories?.data"
                    :key="index"
                    class="table-cell-margin"
                  >
                    <template v-if="item?.forward_status == 'forward_all'">
                      <b-badge pill variant="light-primary">{{
                        `All Forwarded To: ${item?.forwardTo?.data?.title}`
                      }}</b-badge></template
                    >
                    <template v-if="item?.forward_status == 'forward_vat'">
                      <b-badge pill variant="light-primary">{{
                        `VAT Forwarded To: ${item?.forwardTo?.data?.title}`
                      }}</b-badge></template
                    >

                    <template v-if="item?.forward_status == 'forward_tax'">
                      <b-badge pill variant="light-primary">{{
                        `TAX Forwarded To: ${item?.forwardTo?.data?.title}`
                      }}</b-badge></template
                    >
                    <template v-if="item?.forward_status == 'forward_amount'">
                      <b-badge pill variant="light-primary">{{
                        `Amount Forwarded To: ${item?.forwardTo?.data?.title}`
                      }}</b-badge></template
                    >
                  </div>
                </template>
                <template v-else>
                  <b-badge pill variant="light-danger">No Action Done</b-badge>
                </template>
              </template>

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <template
                      v-if="$permissionAbility(INVOICE_CREATE, permissions)"
                    >
                      <template v-if="props?.row?.invoice_created_date">
                        <b-dropdown-item v-on:click="onShowPage(props.row.id)">
                          <feather-icon icon="FileTextIcon" class="mr-50" />
                          <span>Show Invoice</span>
                        </b-dropdown-item>
                      </template>

                      <template
                        v-else-if="
                          !props?.row?.invoice_created_date &&
                          props?.row?.grand_amount > 0
                        "
                      >
                        <b-dropdown-item
                          v-on:click="onShowInvoice(props.row?.id)"
                        >
                          <feather-icon icon="FileTextIcon" class="mr-50" />
                          <span>Create Invoice</span>
                        </b-dropdown-item>
                      </template>

                      <b-dropdown-item
                        v-if="!props?.row?.milestoneTaskBoard?.data"
                        v-on:click="createMilestoneTaskBoard(props.row)"
                      >
                        <feather-icon icon="BriefcaseIcon" class="mr-50" />
                        <span>Create Task Board</span>
                      </b-dropdown-item>
                    </template>

                    <!-- <template
                      v-if="
                        $permissionAbility(
                          INVOICE_COLLECT_PAYMENT_ACCESS,
                          permissions
                        )
                      "
                    >
                      <template v-if="props?.row?.invoice_created_date">
                        <b-dropdown-item v-on:click="onShowPage(props.row.id)">
                          <feather-icon icon="FastForwardIcon" class="mr-50" />
                          <span>{{
                            props?.row?.status == "due"
                              ? "Collect Payment"
                              : "View Invoice"
                          }}</span>
                        </b-dropdown-item>
                      </template>
                    </template> -->
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100', '500']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-card>
      <!-- Transaction Modal -->
      <b-modal
        id="transaction-modal"
        centered
        title="Milestone Transaction Details"
        hide-footer
        @hidden="hiddenTransactionModal"
        no-close-on-backdrop
        size="lg"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(item, index) in updatedAmountHistories"
            :key="index"
          >
            <!-- <template v-if="index > 0">
              {{ transactionHistories[index - 1] }}
            </template> -->

            <template v-if="item?.is_initial">
              <b-badge variant="light-primary">Initial</b-badge>
              <br />
              <b-row>
                <b-col> Amount: {{ item?.subTotal }}</b-col>
                <b-col> VAT :{{ item?.vat }}</b-col>
                <b-col>TAX:{{ item?.tax }}</b-col>
                <b-col>Total: {{ item?.grandTotal }}</b-col>
              </b-row>
            </template>
            <template v-else-if="item?.is_invoice">
              <div class="mb-25">
                <b-badge variant="light-danger">Payment</b-badge> :
                <b-badge variant="light-primary">{{
                  transactionHistories[index - 1].invoice_no
                }}</b-badge>
              </div>
              <br />
              <b-row>
                <b-col>
                  Amount:
                  {{ updatedAmountHistories[index - 1].subTotal }} -
                  {{ transactionHistories[index - 1].sub_total_amount }}
                </b-col>
                <b-col>
                  VAT :{{ updatedAmountHistories[index - 1].vat }} -
                  {{ transactionHistories[index - 1].vat_amount }}
                </b-col>
                <b-col
                  >TAX: {{ updatedAmountHistories[index - 1].tax }} -
                  {{ transactionHistories[index - 1].tax_amount }} -
                </b-col>
                <b-col>Total: {{ item?.grandTotal }}</b-col>
              </b-row>

              <b-row>
                <b-col> Amount: {{ item?.subTotal }}</b-col>
                <b-col> VAT :{{ item?.vat }}</b-col>
                <b-col>TAX:{{ item?.tax }}</b-col>
                <b-col>Total: {{ item?.grandTotal }}</b-col>
              </b-row>
            </template>
            <template v-else-if="item?.is_forward_to">
              <div class="mb-25">
                <b-badge variant="light-danger">Forwarded To</b-badge> :
                <b-badge variant="light-primary">
                  {{
                    transactionHistories[index - 1].forwardTo?.data?.title
                  }}</b-badge
                >
              </div>

              <br />
              <b-row>
                <b-col>
                  Amount: {{ updatedAmountHistories[index - 1].subTotal }} -
                  {{ transactionHistories[index - 1].amount }}</b-col
                >
                <b-col>
                  VAT :{{ updatedAmountHistories[index - 1].vat }} -
                  {{ transactionHistories[index - 1].vat_amount }}</b-col
                >
                <b-col
                  >TAX:{{ updatedAmountHistories[index - 1].tax }} -
                  {{ transactionHistories[index - 1].tax_amount }}
                </b-col>
                <b-col>Total: {{ item?.grandTotal }} </b-col>
              </b-row>

              <b-row>
                <b-col> Amount: {{ item?.subTotal }}</b-col>
                <b-col> VAT :{{ item?.vat }}</b-col>
                <b-col>TAX:{{ item?.tax }}</b-col>
                <b-col>Total: {{ item?.grandTotal }}</b-col>
              </b-row>
            </template>
            <template v-else-if="item?.is_forward_from">
              <div class="mb-25">
                <b-badge variant="light-success"> Forwarded From</b-badge> :
                <b-badge variant="light-primary">
                  {{
                    transactionHistories[index - 1].forwardFrom?.data?.title
                  }}</b-badge
                >
              </div>

              <br />

              <b-row>
                <b-col>
                  Amount: {{ updatedAmountHistories[index - 1].subTotal }} +
                  {{ transactionHistories[index - 1].amount }}</b-col
                >
                <b-col>
                  VAT :{{ updatedAmountHistories[index - 1].vat }} +
                  {{ transactionHistories[index - 1].vat_amount }}</b-col
                >
                <b-col
                  >TAX:{{ updatedAmountHistories[index - 1].tax }}+
                  {{ transactionHistories[index - 1].tax_amount }}
                </b-col>
                <b-col>Total: {{ item?.grandTotal }} </b-col>
              </b-row>

              <b-row>
                <b-col> Amount: {{ item?.subTotal }}</b-col>
                <b-col> VAT :{{ item?.vat }}</b-col>
                <b-col>TAX:{{ item?.tax }}</b-col>
                <b-col>Total: {{ item?.grandTotal }}</b-col>
              </b-row>
            </template>
          </b-list-group-item>
        </b-list-group>
      </b-modal>

      <!-- Milestone Forward Modal-->
      <b-modal
        id="modal-forward-form"
        centered
        title="Forward Milestone Information Form"
        hide-footer
        @hidden="hideForwardMilestoneModal"
        no-close-on-backdrop
        size="xl"
      >
        <validation-observer ref="forwardValidation">
          <b-form v-on:submit.prevent="forwardValidationForm">
            <b-row>
              <!-- select project -->
              <b-col md="6" lg="6" xs="12">
                <b-form-group label="Project *" label-for="project">
                  <ValidationProvider
                    name="project"
                    v-slot="{ errors }"
                    vid="project_id"
                    rules="required"
                  >
                    <v-select
                      id="project"
                      placeholder="Choose Project"
                      v-model="projectId"
                      :options="projectIdOption"
                      :reduce="(item) => item.id"
                      v-on:input="setProjectWiseMilestone"
                      label="name"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <!-- select milestone -->
              <b-col md="6" lg="6" xs="12">
                <b-form-group label="Milestone *" label-for="milestone">
                  <ValidationProvider
                    name="milestone"
                    v-slot="{ errors }"
                    vid="milestone_id"
                    rules="required"
                  >
                    <v-select
                      id="milestone"
                      placeholder="Choose Milestone"
                      v-model="milestoneId"
                      :options="milestoneIdOption"
                      v-on:input="setMilestoneAmount"
                      :reduce="(item) => item.id"
                      label="name"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="3" lg="3" xs="12">
                <b-form-group label="Amount" label-for="amount">
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    vid="amount"
                  >
                    <b-form-input
                      id="name"
                      type="text"
                      v-model="amount"
                      placeholder="Milestone Amount"
                      :state="errors.length > 0 ? false : null"
                      :disabled="true"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3" lg="3" xs="12">
                <b-form-group label="VAT" label-for="vat">
                  <validation-provider
                    #default="{ errors }"
                    name="vat"
                    vid="vat"
                  >
                    <b-form-input
                      id="name"
                      type="text"
                      v-model="vat"
                      placeholder="Milestone VAT"
                      :state="errors.length > 0 ? false : null"
                      :disabled="true"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3" lg="3" xs="12">
                <b-form-group label="TAX" label-for="tax">
                  <validation-provider
                    #default="{ errors }"
                    name="tax"
                    vid="tax"
                  >
                    <b-form-input
                      id="name"
                      type="text"
                      v-model="tax"
                      placeholder="Milestone TAX"
                      :state="errors.length > 0 ? false : null"
                      :disabled="true"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3" lg="3" xs="12">
                <b-form-group label="Total Amount" label-for="toal_amount">
                  <validation-provider
                    #default="{ errors }"
                    name="toal_amount"
                    vid="toal_amount"
                  >
                    <b-form-input
                      id="toal_amount"
                      type="text"
                      v-model="totalAmount"
                      placeholder="Total Amount"
                      :state="errors.length > 0 ? false : null"
                      :disabled="true"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" lg="12" xs="12">
                <div class="mt-1 ml-2">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="selectedForwardOption"
                      :aria-describedby="ariaDescribedby"
                      v-on:change="getForwardValue"
                      name="radio-options"
                    >
                      <b-row>
                        <b-col>
                          <b-form-radio value="none"
                            >No Forwarding</b-form-radio
                          >
                        </b-col>
                        <b-col>
                          <b-form-radio
                            value="forward_all"
                            :disabled="allForwarded"
                            >Forward All</b-form-radio
                          >
                        </b-col>
                        <b-col>
                          <b-form-radio
                            value="forward_amount"
                            :disabled="onlyAmountForwarded"
                            >Forward Amount</b-form-radio
                          >
                        </b-col>
                        <b-col>
                          <b-form-radio
                            value="forward_vat"
                            :disabled="onlyVatForwarded"
                            >Forward VAT</b-form-radio
                          >
                        </b-col>
                        <b-col>
                          <b-form-radio
                            value="forward_tax"
                            :disabled="onlyTaxForwarded"
                            >Forward TAX</b-form-radio
                          >
                        </b-col>
                      </b-row>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </b-col>
              <template v-if="forwardedToNext">
                <!-- forwarded project -->
                <b-col md="6" lg="6" xs="12">
                  <b-form-group label="Project" label-for="project">
                    <ValidationProvider
                      name="project"
                      v-slot="{ errors }"
                      vid="project_id"
                      rules="required"
                    >
                      <v-select
                        id="project"
                        placeholder="Choose Project"
                        v-model="projectId"
                        :options="projectIdOption"
                        :reduce="(item) => item.id"
                        label="name"
                        class="custom-font"
                        :disabled="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <!-- forwarded milestone -->
                <b-col md="6" lg="6" xs="12">
                  <b-form-group
                    label="Forwarding Milestone"
                    label-for="milestone"
                  >
                    <ValidationProvider
                      name="forwarding milestone"
                      v-slot="{ errors }"
                      vid="forward_milestone_id"
                      rules="required"
                    >
                      <v-select
                        id="milestone"
                        placeholder="Choose Milestone"
                        v-model="nextMiletoneId"
                        :options="nextMilestoneIdOption"
                        :reduce="(item) => item.id"
                        label="name"
                        class="custom-font"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>

                <b-col md="12" lg="12" xs="12">
                  <!-- remarks -->
                  <b-form-group label="Remarks *" label-for="remarks">
                    <ValidationProvider
                      name="remarks"
                      v-slot="{ errors }"
                      vid="remarks"
                      rules="required"
                    >
                      <b-form-textarea
                        id="remarks"
                        type="text"
                        v-model="forwardRemarks"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Add Remarks"
                        class="custom-font"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </template>
            </b-row>

            <!-- loading button -->
            <template v-if="isIncomeFormLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="!forwardedToNext"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>

      <b-modal
        id="modal-board-form"
        centered
        title="Update Milestone Info"
        hide-footer
        @hidden="hiddenModalBoardModal"
        no-close-on-backdrop
      >
        <validation-observer ref="milestoneBoardValidation">
          <b-form v-on:submit.prevent="milestoneBoardValidationForm">
            <b-form-group label="Board Title" label-for="board_title">
              <validation-provider
                #default="{ errors }"
                name="board_title"
                vid="board_title"
              >
                <b-form-input
                  id="board_title"
                  type="text"
                  v-model="boardTitle"
                  placeholder="Board Title"
                  :state="errors.length > 0 ? false : null"
                  class="custom-font"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Milestone Deadline " label-for="head-id">
              <ValidationProvider
                name="milestone_deadline"
                v-slot="{ errors }"
                vid="milestone_deadline"
                rules="required"
              >
                <b-form-datepicker
                  locale="en-US"
                  v-model="newMilestoneDeadline"
                  placeholder="Milestone Deadline"
                  class="d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <!-- loading button -->
            <template v-if="isBoardCreateFormLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                Create
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- Create Invoice Modal-->
      <!-- <b-modal
        id="modal-invoice-form"
        centered
        title="Invoice Information Form"
        hide-footer
        @hidden="hideInvoiceModal"
        no-close-on-backdrop
        size="lg"
      >
        <validation-observer ref="invoiceValidation">
          <b-form v-on:submit.prevent="invoiceValidationForm">
            <b-row>

              <b-col md="6" lg="6" xs="12">
                <b-form-group label="Project" label-for="project">
                  <ValidationProvider
                    name="project"
                    v-slot="{ errors }"
                    vid="project_id"
                    rules="required"
                  >
                    <v-select
                      id="project"
                      placeholder="Choose Project"
                      v-model="projectId"
                      :options="projectIdOption"
                      :reduce="(item) => item.id"
                      v-on:input="setProjectWiseMilestone"
                      :disabled="selectDisabled"
                      label="name"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6" lg="6" xs="12">
                <b-form-group label="Milestone" label-for="milestone">
                  <ValidationProvider
                    name="milestone"
                    v-slot="{ errors }"
                    vid="milestone_id"
                    rules="required"
                  >
                    <v-select
                      id="milestone"
                      placeholder="Choose Milestone"
                      v-model="milestoneId"
                      :options="milestoneIdOption"
                      v-on:input="setMilestoneAmount"
                      :reduce="(item) => item.id"
                      label="name"
                      class="custom-font"
                      :disabled="selectDisabled"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="12" lg="12" xs="12">
                <b-form-group label="Amount" label-for="amount">
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    vid="amount"
                  >
                    <b-form-input
                      id="name"
                      type="text"
                      v-model="amount"
                      placeholder="Selected Milestone Amount"
                      :state="errors.length > 0 ? false : null"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <template v-if="isInvoiceFormLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>


            <template v-else>
              <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                Create
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal> -->
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BRow,
  BCol,
  BFormTags,
  BFormDatepicker,
  BFormFile,
  BFormTextarea,
  BFormCheckbox,
  VBTooltip,
  BFormRadio,
  BCardText,
  BFormRadioGroup,
  BTable,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { paymentTypeConstants } from "@/helpers/constant/paymentTypeConstant";
import { milestoneTypeConstants } from "@/helpers/constant/milestoneTypeConstant";

import { milestoneStatusConstants } from "@/helpers/constant/milestoneStatusConstant";

import {
  MILESTONE_FORWARD_ACCESS,
  MILESTONE_CREATE,
  MILESTONE_DELETE,
  INVOICE_CREATE,
  INVOICE_COLLECT_PAYMENT_ACCESS,
} from "@/helpers/permissionsConstant";
import flatPickr from 'vue-flatpickr-component'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'
import { milestoneTaskBoardStatusConstants } from '@/helpers/constant/milestoneTaskBoardStatusConstant'

export default {
  name: "PayableReceivableView",
  components: {
    FormatCurrency,
    BForm,
    BButton,
    BCard,
    BCardText,
    BLink,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BTable,
    quillEditor,
    BRow,
    BCol,
    BFormTags,
    BFormDatepicker,
    BFormFile,
    BFormTextarea,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    flatPickr,
    BCardActions
  },

  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      milestoneTaskBoardStatusConstants,
      MILESTONE_FORWARD_ACCESS,
      MILESTONE_CREATE,
      MILESTONE_DELETE,
      INVOICE_CREATE,
      INVOICE_COLLECT_PAYMENT_ACCESS,
      filterStartDate: "",
      filterRangeDate: "",
      filterEndDate: "",
      filterPaymentType: "",
      paymentTypeConstants,
      filterMilestoneType: "",
      milestoneTypeConstants,
      filterMilestoneStatus: "",
      milestoneStatusConstants,
      onlyTaxForwarded: false,
      onlyVatForwarded: false,
      onlyAmountForwarded: false,

      allForwarded: false,
      fields: ["type", "milestone", "amount", "vat", "tax", "total_amount"],
      items: [],
      //Income
      allMileStones: "",
      projectId: "",
      projectIdOption: [],
      allMilestoneBoard: [],
      projectMilestones: "",
      amount: "",
      tax: "",
      vat: "",
      totalAmount: "",
      milestoneId: "",
      milestoneIdOption: [],
      nextMiletoneId: "",
      nextMilestoneIdOption: [],
      accountId: "",
      accountIdOption: [],
      attachment: null,
      remarks: "",
      forwardedToNext: false,
      forwardRemarks: "",
      selectedForwardOption: "none",
      forwardOptions: [
        { text: "No Forwarding", value: "none" },
        { text: "Forward All To Next Milestone", value: "forward_all" },
        { text: "Forward Only Amount", value: "forward_amount" },
        { text: "Forward Only VAT", value: "forward_vat" },
        { text: "Forward Only TAX", value: "forward_tax" },
      ],
      //Invoice
      selectDisabled: false,

      pageLength: 50,
      columns: [
        {
          label: "Deadline",
          field: "date",
          formatFn: this.formatFnTableCreatedDate,
          sortable: false,
        },
        {
          label: "Milestone Info",
          field: "format_title",
          sortable: false,
        },

        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },
        {
          label: "Initial Amount",
          field: "format_initial_amount",
          sortable: false,
        },
        // {
        //   label: "Paid/Forwarded Amount",
        //   field: "format_paid_amount",
        //   sortable: false,
        // },
        {
          label: "Remaining Amount ",
          field: "format_amount",
          sortable: false,
        },
        {
          label: "Payment Deadline",
          field: "format_payment_deadline",
          sortable: false,
        },

        {
          label: "Status",
          field: "format_status",
          sortable: false,
        },
        {
          label: "Forwarded To",
          field: "format_forward_to",
          sortable: false,
        },
        {
          label: "Forwarded From",
          field: "format_forward_from",
          sortable: false,
        },

        // {
        //   label: "Invoice Info",
        //   field: "format_invoice_info",
        //   sortable: false,
        // },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      params: [],
      rows: [],
      searchTerm: "",
      newMilestoneDeadline: "",
      boardTitle: "",
      isLoading: false,
      isIncomeFormLoading: false,
      isInvoiceFormLoading: false,
      isBoardCreateFormLoading: false,
      totalRecords: 0,
      transactionHistories: [],
      updatedAmountHistories: [],

      initialSubTotal: 0,
      initialVatTotal: 0,
      initialTaxTotal: 0,
      initialGrantTotal: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          // { field: "id", type: "desc" },
          // { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 50,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const [projects, accounts, getMilestoneBoard] = await Promise.all([
        this.getApprovedProjects(),
        this.getActiveAccountInfo(),
        this.getMilestoneBoardInfo(),
      ]);

      this.allMilestoneBoard = getMilestoneBoard?.data?.data;

      // approved projects
      this.projectIdOption = (projects?.data?.data || []).map((item) => {
        let name = item?.lead?.title;

        return {
          name,
          id: item.id,
        };
      });

      //active accounts
      this.accountIdOption = (accounts?.data?.data || []).map((item) => {
        let name = `${item?.name} (${item?.balance})`;

        return {
          name,
          id: item.id,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    setStatus(status) {
      const statusType = (this.milestoneTaskBoardStatusConstants || []).find(
          (item) => item?.value == status
      );

      return statusType?.name;
    },
    setStatusVariant(status) {
      const statusType = (this.milestoneTaskBoardStatusConstants || []).find(
          (item) => item?.value == status
      );

      return statusType?.badgeVariant;
    },
    handleDateRangeChange()
    {
      if (this.filterRangeDate && this.filterRangeDate !== '') {
        const dates = this.filterRangeDate.split(' to ');

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format');
          // You can choose to handle this case differently based on your requirements
        }
      }
    },
    calculateAmount(initial, transactionAmount) {
      this.initialSubTotal = initial - transactionAmount;
      return this.initialSubTotal;
    },
    hiddenTransactionModal() {
      this.$bvModal.hide("transaction-modal");
      this.transactionHistories = [];
      this.updatedAmountHistories = [];
    },
    showTransactionHistory(milestone) {
      const invoicesData = milestone?.invoices?.data;
      const paidInvoicesData =
        invoicesData?.filter((item) => item?.status === "paid") || [];

      paidInvoicesData.forEach((item) => {
        item.is_invoice = true;
        item.created_at = item.payment_date;
      });

      const milestoneForwardToHistoriesData =
        milestone?.milestoneForwardToHistories?.data;

      milestoneForwardToHistoriesData.forEach((item) => {
        item.is_forward_to = true;
      });
      const milestoneForwardFromHistoriesData =
        milestone?.milestoneForwardFromHistories?.data;

      milestoneForwardFromHistoriesData.forEach((item) => {
        item.is_forward_from = true;
      });

      const mergedArray = [
        ...paidInvoicesData,
        ...milestoneForwardToHistoriesData,
        ...milestoneForwardFromHistoriesData,
      ];

      mergedArray.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );

      this.transactionHistories = mergedArray;

      let initialSubTotal = milestone?.initial_total_amount;
      let initialVatTotal = milestone?.initial_total_vat_amount;
      let initialTaxTotal = milestone?.initial_total_tax_amount;
      let initialGrandTotal = milestone?.initial_grand_amount;
      let logEntry = {};
      logEntry = {
        is_initial: true,
        subTotal: initialSubTotal,
        vat: initialVatTotal,
        tax: initialTaxTotal,
        grandTotal: initialGrandTotal,
      };
      this.updatedAmountHistories.push(logEntry);

      mergedArray.forEach((item) => {
        if (item?.is_invoice) {
          initialSubTotal = initialSubTotal - item?.sub_total_amount;
          initialVatTotal = initialVatTotal - item?.vat_amount;
          initialTaxTotal = initialTaxTotal - item?.tax_amount;
          initialGrandTotal = initialGrandTotal - item?.grand_total_amount;

          logEntry = {
            is_invoice: true,
            subTotal: initialSubTotal,
            vat: initialVatTotal,
            tax: initialTaxTotal,
            grandTotal: initialGrandTotal,
          };
        } else if (item?.is_forward_to) {
          initialSubTotal = initialSubTotal - item?.amount;
          initialVatTotal = initialVatTotal - item?.vat_amount;
          initialTaxTotal = initialTaxTotal - item?.tax_amount;
          initialGrandTotal =
            initialSubTotal + initialVatTotal + initialTaxTotal;

          logEntry = {
            is_forward_to: true,
            subTotal: initialSubTotal,
            vat: initialVatTotal,
            tax: initialTaxTotal,
            grandTotal: initialGrandTotal,
          };
        } else if (item?.is_forward_from) {
          initialSubTotal = initialSubTotal + item?.amount;

          initialVatTotal = initialVatTotal + item?.vat_amount;
          initialTaxTotal = initialTaxTotal + item?.tax_amount;
          initialGrandTotal =
            initialSubTotal + initialVatTotal + initialTaxTotal;
          logEntry = {
            is_forward_from: true,
            subTotal: initialSubTotal,
            vat: initialVatTotal,
            tax: initialTaxTotal,
            grandTotal: initialGrandTotal,
          };
        }
        this.updatedAmountHistories.push(logEntry);
      });

      this.$bvModal.show("transaction-modal");
    },
    async setExpenseAmount() {
      const selectedExpense = (this.allExpenses?.data?.data || []).find(
        (item) => {
          if (item?.id === this.expenseId) {
            return item;
          }
        }
      );
      this.expenseAccountId = selectedExpense?.account_id;
      this.expenseAmount = selectedExpense?.amount;
    },
    async setMilestoneAmount() {
      const selectedMilestone = (this.projectMilestones?.data?.data || []).find(
        (item) => {
          if (item?.id === this.milestoneId) {
            return item;
          }
        }
      );
      this.onlyAmountForwarded = false;
      this.onlyTaxForwarded = false;
      this.onlyVatForwarded = false;
      this.allForwarded = false;

      if (selectedMilestone?.amount_forward_status == "forward_from") {
        this.onlyAmountForwarded = true;
        this.allForwarded = true;
      }
      if (selectedMilestone?.vat_forward_status == "forward_from") {
        this.onlyVatForwarded = true;
        this.allForwarded = true;
      }
      if (selectedMilestone?.tax_forward_status == "forward_from") {
        this.onlyTaxForwarded = true;
        this.allForwarded = true;
      }

      this.amount = selectedMilestone?.total_amount;
      this.vat = selectedMilestone?.total_vat_amount;
      this.tax = selectedMilestone?.total_tax_amount;
      this.totalAmount = selectedMilestone?.grand_amount;

      const nextMilestones = this.projectMilestones?.data?.data;

      if (this.nextMiletoneId == this.milestoneId) {
        this.nextMiletoneId = "";
      }
      const filterMilestones = (nextMilestones || []).filter((item) => {
        return item?.id > this.milestoneId;
      });

      this.nextMilestoneIdOption = (filterMilestones || []).map((item) => {
        let name = item?.title;
        let id = item?.id;

        return {
          name,
          id,
        };
      });
    },
    async setProjectWiseMilestone() {
      this.milestoneId = "";
      if (this.projectId) {
        this.projectMilestones = await this.getNonPaidMilestones();

        // due milestones
        this.milestoneIdOption = (this.projectMilestones?.data?.data || []).map(
          (item) => {
            let name = item?.title;

            return {
              name,
              id: item.id,
            };
          }
        );
      } else {
        this.milestoneId = "";
      }
    },

    async getMilestoneBoardInfo() {
      return await this.$api.get("api/milestone-task-boards");
    },
    async getActiveAccountInfo() {
      return await this.$api.get("api/accounts/active/all");
    },
    async getDueMilestones() {
      return await this.$api.get(
        `api/projects/${this.projectId}/due-milestones`
      );
    },

    async getNonPaidMilestones() {
      return await this.$api.get(
        `api/projects/${this.projectId}/non-paid-milestones`
      );
    },
    async getProjectMilestones() {
      return await this.$api.get(`api/projects/${this.projectId}/milestones`);
    },
    async getApprovedProjects(params) {
      return await this.$api.get("api/projects/approve");
    },
    async getProjects(params) {
      return await this.$api.get("api/projects/approve");
    },
    async getApproveProjects() {
      return await this.$api.get("api/projects/approve");
    },

    async getMilestones(params) {
      return await this.$api.get(
        "api/milestones?include=project,milestoneForwardFromHistories.forwardFrom,milestoneForwardFromHistories.forwardTo,milestoneForwardToHistories.forwardFrom,milestoneForwardToHistories.forwardTo,milestoneTaskBoard,invoices",
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
            filterStartDate: params.filterStartDate,
            filterEndDate: params.filterEndDate,
            filterPaymentType: params.filterPaymentType,
            filterMilestoneType: params.filterMilestoneType,
            filterMilestoneStatus: params.filterMilestoneStatus,
          },
        }
      );
    },
    getForwardValue() {
      if (this.selectedForwardOption != "none") {
        this.forwardedToNext = true;
      } else {
        this.forwardedToNext = false;
      }
    },
    formatType(value) {
      if (value) {
        const res = value.charAt(0).toUpperCase() + value.slice(1);
        return res;
      }
      return "N/A";
    },
    formatStatus(value) {
      if (value) {
        return "Approved";
      }
      return "Pending";
    },
    formatMilestoneType(value) {
      if (value) {
        return "Extra";
      }
      return "Primary";
    },
    formatFnTableCreatedDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },
    showMilestoneForwardModal() {
      this.$bvModal.show("modal-forward-form");
    },

    showInvoiceModal() {
      this.$bvModal.show("modal-invoice-form");
    },

    hideInvoiceModal() {
      this.$bvModal.hide("modal-invoice-form");
      this.projectId = "";
      this.milestoneId = "";
      this.amount = "";
      this.selectDisabled = false;
    },

    hiddenModal() {
      this.$bvModal.hide("modal-forward-form");
    },

    hideForwardMilestoneModal() {
      this.$bvModal.hide("modal-forward-form");
      this.forwardedToNext = false;
      this.amount = "";
      this.vat = "";
      this.tax = "";
      this.totalAmount = "";
      this.projectId = "";
      this.milestoneId = "";
      this.nextMiletoneId = "";
      this.accountId = "";
      this.attachment = null;
      this.forwardRemarks = "";
      this.remarks = "";
      this.projectMilestones = "";
      this.selectedForwardOption = "none";
    },

    onShowPage(id) {
      this.$router.push({
        name: "admin-milestone-invoice",
        params: { id },
      });
    },

    hiddenModalBoardModal() {
      this.$bvModal.hide("modal-board-form");
      this.milestoneId = "";
      this.newMilestoneDeadline = "";
    },
    createMilestoneTaskBoard(value) {
      this.milestoneId = value?.id;
      this.newMilestoneDeadline = value?.date;
      this.boardTitle = value?.title;
      this.$bvModal.show("modal-board-form");
    },

    async onShowInvoice(id) {
      try {
        await this.$api.get("/api/invoices/check-invoice-settings");
        this.$router.push({
          name: "admin-invoice-details",
          params: { id },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    onTaskBoardPage(id) {
      const boardItem = this.allMilestoneBoard.find(
        (item) => item?.milestone_id === id
      );

      if (boardItem) {
        this.$router.push({
          name: "admin-task-board-details",
          params: { id: boardItem.id },
        });
      }
    },
    filterByDeadline() {
      this.loadItems();
    },
    filterByPaymentType() {
      this.loadItems();
    },
    filterByMilestoneType() {
      this.loadItems();
    },
    filterByMilestoneStatus() {
      this.loadItems();
    },
    filterNoticeByType() {
      this.loadItems();
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      this.params = (params || []).map((item) => {
        let field = item.field;
        if (field == "format_amount") {
          field = "amount";
        }
        return {
          field: field,
          type: item.type,
        };
      });

      this.updateParams({
        sort: this.params,
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async loadItems() {
      try {
        this.handleDateRangeChange()
        const [milestones] = await Promise.all([
          this.getMilestones({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterStartDate: this.filterStartDate,
            filterEndDate: this.filterEndDate,
            filterPaymentType: this.filterPaymentType,
            filterMilestoneType: this.filterMilestoneType,
            filterMilestoneStatus: this.filterMilestoneStatus,
          }),
        ]);
        const data = milestones?.data?.data;

        console.log(data);

        const meta = milestones?.data?.meta;
        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    forwardValidationForm: async function () {
      this.$refs.forwardValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isIncomeFormLoading = true;

            if (this.forwardedToNext) {
              await this.$api.post("/api/milestones/forward", {
                from_milestone_id: this.milestoneId,
                to_milestone_id: this.nextMiletoneId,
                project_id: this.projectId,
                forward: this.selectedForwardOption,
                remarks: this.forwardRemarks,
              });
            } else {
              const formData = new FormData();

              if (this.projectId) {
                formData.append("project_id", this.projectId);
              }
              if (this.milestoneId) {
                formData.append("milestone_id", this.milestoneId);
              }
              if (this.amount) {
                formData.append("amount", this.amount);
              }
              if (this.accountId) {
                formData.append("account_id", this.accountId);
              }

              if (this.remarks) {
                formData.append("remarks", this.remarks);
              }

              if (this.attachment) {
                formData.append("attachment", this.attachment);
              }
              await this.$api.post("/api/milestones/income", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
            }

            this.isIncomeFormLoading = false;

            this.hideForwardMilestoneModal();
            this.loadItems();
            let toasterText;

            if (this.forwardedToNext) {
              toasterText = "Receivable Information Added SuccessFully";
            } else {
              toasterText = "Recivable Forwarded SuccessFully";
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: toasterText,
              },
            });
          } catch (error) {
            this.isIncomeFormLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.forwardValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    invoiceValidationForm: async function () {
      this.$refs.invoiceValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isInvoiceFormLoading = true;

            await this.$api.post(
              `api/milestones/${this.milestoneId}/invoice/create`
            );

            this.isInvoiceFormLoading = false;

            this.hideInvoiceModal();
            this.loadItems();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Invoice Created Successfuly",
              },
            });
          } catch (error) {
            this.isInvoiceFormLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.forwardValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    milestoneBoardValidationForm: async function () {
      this.$refs.milestoneBoardValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isBoardCreateFormLoading = true;

            await this.$api.post(
              `api/milestone/${this.milestoneId}/create-task-board`,
              {
                board_title: this.boardTitle,
                milestone_deadline: this.newMilestoneDeadline,
              }
            );

            this.isBoardCreateFormLoading = false;

            this.hiddenModalBoardModal();
            this.loadItems();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Task Board Created Successfully",
              },
            });
          } catch (error) {
            this.isBoardCreateFormLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.milestoneBoardValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.table-custom-style {
  font-size: 12px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}

.line-through-active {
  text-decoration: line-through;
}
.table-cell-margin {
  margin: 8px 0px;
}
</style>
