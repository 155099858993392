var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('div', [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "12"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "placeholder": "Search By Milestone Title",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Enter Deadline Range",
      "config": {
        mode: 'range'
      }
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.paymentTypeConstants,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "By Payment Type"
    },
    on: {
      "input": _vm.filterByPaymentType
    },
    model: {
      value: _vm.filterPaymentType,
      callback: function callback($$v) {
        _vm.filterPaymentType = $$v;
      },
      expression: "filterPaymentType"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.milestoneTypeConstants,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "By Milestone Type"
    },
    on: {
      "input": _vm.filterByMilestoneType
    },
    model: {
      value: _vm.filterMilestoneType,
      callback: function callback($$v) {
        _vm.filterMilestoneType = $$v;
      },
      expression: "filterMilestoneType"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.milestoneStatusConstants,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": " By Milestone Status"
    },
    on: {
      "input": _vm.filterByMilestoneStatus
    },
    model: {
      value: _vm.filterMilestoneStatus,
      callback: function callback($$v) {
        _vm.filterMilestoneStatus = $$v;
      },
      expression: "filterMilestoneStatus"
    }
  })], 1)], 1)], 1)])], 1), _c('div', [_c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.MILESTONE_FORWARD_ACCESS, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "gradient-primary"
    },
    on: {
      "click": _vm.showMilestoneForwardModal
    }
  }, [_vm._v(" Forward Payment ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "rows": _vm.rows,
      "columns": _vm.columns,
      "styleClass": "vgt-table table-custom-style striped",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'date',
          type: 'asc'
        }]
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$row3, _props$row4, _props$row5, _props$row6, _props$row7, _props$row8, _props$row9, _props$row10, _props$column2, _props$row11, _props$column3, _props$row12, _props$row13, _props$row14, _props$row15, _props$row16, _props$row17, _props$row18, _props$row19, _props$column4, _props$row20, _props$row21, _props$row22, _props$row23, _props$row24, _props$row25, _props$row26, _props$row27, _props$column5, _props$row28, _props$row29, _props$column6, _props$row30, _props$row31, _props$column7, _props$row32, _props$row33, _props$row34, _props$row35, _props$row36, _props$column8, _props$row37, _props$row38, _props$row39, _props$row40, _props$row41, _props$row41$mileston, _props$row42, _props$row42$mileston, _props$row43, _props$row43$mileston, _props$row43$mileston2, _props$row44, _props$row44$mileston, _props$row44$mileston2, _props$column9, _props$row45, _props$row45$mileston, _props$row46, _props$row46$mileston, _props$column10, _props$row47, _props$row47$mileston, _props$row48, _props$row48$mileston, _props$row49, _props$row50, _props$row51, _props$row53, _props$row53$mileston;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_title' ? [(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.status) == 'paid' ? _c('b-link', {
          on: {
            "click": function click($event) {
              var _props$row2;
              return _vm.onTaskBoardPage(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.id);
            }
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.title) + " "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatMilestoneType(props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.is_extra)))])], 1) : _c('span', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.title) + " "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatMilestoneType(props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.is_extra)))])], 1), props !== null && props !== void 0 && (_props$row7 = props.row) !== null && _props$row7 !== void 0 && _props$row7.payment_type ? _c('div', {
          staticClass: "mt-1 mb-1"
        }, [_vm._v(" Payment Type: "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatType(props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.payment_type)))])], 1) : _vm._e(), props !== null && props !== void 0 && (_props$row9 = props.row) !== null && _props$row9 !== void 0 && _props$row9.payment_type ? _c('div', {
          staticClass: "mt-1 mb-1"
        }, [_vm._v(" Created On: "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.created_at))])]) : _vm._e()] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_currency' ? [_c('FormatCurrency', {
          attrs: {
            "type": 'currency',
            "currency": props === null || props === void 0 ? void 0 : (_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.currency
          }
        })] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_amount' ? [_c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Amount: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.total_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.currency
          }
        })], 1)]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" VAT: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.total_vat_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.currency
          }
        })], 1)]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" TAX: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.total_tax_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : _props$row17.currency
          }
        })], 1)]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Total Amount: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : _props$row18.grand_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row19 = props.row) === null || _props$row19 === void 0 ? void 0 : _props$row19.currency
          }
        })], 1)]), [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-sm",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.showTransactionHistory(props.row);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)]] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_initial_amount' ? [_c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Total Amount: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row20 = props.row) === null || _props$row20 === void 0 ? void 0 : _props$row20.initial_total_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row21 = props.row) === null || _props$row21 === void 0 ? void 0 : _props$row21.currency
          }
        })], 1)]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Total VAT: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row22 = props.row) === null || _props$row22 === void 0 ? void 0 : _props$row22.initial_total_vat_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row23 = props.row) === null || _props$row23 === void 0 ? void 0 : _props$row23.currency
          }
        })], 1)]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Total TAX: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row24 = props.row) === null || _props$row24 === void 0 ? void 0 : _props$row24.initial_total_tax_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row25 = props.row) === null || _props$row25 === void 0 ? void 0 : _props$row25.currency
          }
        })], 1)]), _c('div', {
          staticClass: "table-cell-margin"
        }, [_vm._v(" Grand Total Amount: "), _c('b', [_c('FormatCurrency', {
          attrs: {
            "amount": props === null || props === void 0 ? void 0 : (_props$row26 = props.row) === null || _props$row26 === void 0 ? void 0 : _props$row26.initial_grand_amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row27 = props.row) === null || _props$row27 === void 0 ? void 0 : _props$row27.currency
          }
        })], 1)])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'format_payment_type' ? [props !== null && props !== void 0 && (_props$row28 = props.row) !== null && _props$row28 !== void 0 && _props$row28.payment_type ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatType(props === null || props === void 0 ? void 0 : (_props$row29 = props.row) === null || _props$row29 === void 0 ? void 0 : _props$row29.payment_type)))])] : _vm._e()] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column6 = props.column) === null || _props$column6 === void 0 ? void 0 : _props$column6.field) === 'format_payment_deadline' ? [props !== null && props !== void 0 && (_props$row30 = props.row) !== null && _props$row30 !== void 0 && _props$row30.payment_deadline ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-success"
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row31 = props.row) === null || _props$row31 === void 0 ? void 0 : _props$row31.payment_deadline))])] : [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-danger"
          }
        }, [_vm._v("Not Set")])]] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column7 = props.column) === null || _props$column7 === void 0 ? void 0 : _props$column7.field) === 'format_invoice_info' ? [props !== null && props !== void 0 && (_props$row32 = props.row) !== null && _props$row32 !== void 0 && _props$row32.invoice_created_date ? [props !== null && props !== void 0 && (_props$row33 = props.row) !== null && _props$row33 !== void 0 && _props$row33.invoice_no ? [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row34 = props.row) === null || _props$row34 === void 0 ? void 0 : _props$row34.invoice_no))])], 1)] : _vm._e(), props !== null && props !== void 0 && (_props$row35 = props.row) !== null && _props$row35 !== void 0 && _props$row35.invoice_created_date ? [_c('div', {
          staticClass: "table-cell-margin"
        }, [_c('b', [_vm._v("Created On: ")]), _c('span', [_vm._v(" " + _vm._s(_vm.formatFnTableCreatedDate(props === null || props === void 0 ? void 0 : (_props$row36 = props.row) === null || _props$row36 === void 0 ? void 0 : _props$row36.invoice_created_date)))])])] : _vm._e()] : [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-danger"
          }
        }, [_vm._v("Not Generated")])]] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column8 = props.column) === null || _props$column8 === void 0 ? void 0 : _props$column8.field) === 'format_status' ? [(props === null || props === void 0 ? void 0 : (_props$row37 = props.row) === null || _props$row37 === void 0 ? void 0 : _props$row37.status) == 'paid' ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-success"
          }
        }, [_vm._v("Paid")])] : (props === null || props === void 0 ? void 0 : (_props$row38 = props.row) === null || _props$row38 === void 0 ? void 0 : _props$row38.status) == 'partial_paid' ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-info"
          }
        }, [_vm._v("Partially Paid")])] : (props === null || props === void 0 ? void 0 : (_props$row39 = props.row) === null || _props$row39 === void 0 ? void 0 : _props$row39.status) == 'due' ? [_c('div', [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-danger"
          }
        }, [_vm._v("Due")])], 1)] : (props === null || props === void 0 ? void 0 : (_props$row40 = props.row) === null || _props$row40 === void 0 ? void 0 : _props$row40.status) == 'forward' ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-warning"
          }
        }, [_vm._v("All Forwarded")])] : _vm._e(), _c('br'), props !== null && props !== void 0 && (_props$row41 = props.row) !== null && _props$row41 !== void 0 && (_props$row41$mileston = _props$row41.milestoneTaskBoard) !== null && _props$row41$mileston !== void 0 && _props$row41$mileston.data ? [_c('b-badge', {
          staticClass: "mt-1",
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v("Task Board Created ")])] : _vm._e(), props !== null && props !== void 0 && (_props$row42 = props.row) !== null && _props$row42 !== void 0 && (_props$row42$mileston = _props$row42.milestoneTaskBoard) !== null && _props$row42$mileston !== void 0 && _props$row42$mileston.data ? [_c('br'), _c('b-badge', {
          staticClass: "mt-1",
          attrs: {
            "pill": "",
            "variant": _vm.setStatusVariant(props === null || props === void 0 ? void 0 : (_props$row43 = props.row) === null || _props$row43 === void 0 ? void 0 : (_props$row43$mileston = _props$row43.milestoneTaskBoard) === null || _props$row43$mileston === void 0 ? void 0 : (_props$row43$mileston2 = _props$row43$mileston.data) === null || _props$row43$mileston2 === void 0 ? void 0 : _props$row43$mileston2.status)
          }
        }, [_vm._v("Task Board " + _vm._s(_vm.setStatus(props === null || props === void 0 ? void 0 : (_props$row44 = props.row) === null || _props$row44 === void 0 ? void 0 : (_props$row44$mileston = _props$row44.milestoneTaskBoard) === null || _props$row44$mileston === void 0 ? void 0 : (_props$row44$mileston2 = _props$row44$mileston.data) === null || _props$row44$mileston2 === void 0 ? void 0 : _props$row44$mileston2.status)) + " ")])] : _vm._e()] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column9 = props.column) === null || _props$column9 === void 0 ? void 0 : _props$column9.field) === 'format_forward_from' ? [[(props === null || props === void 0 ? void 0 : (_props$row45 = props.row) === null || _props$row45 === void 0 ? void 0 : (_props$row45$mileston = _props$row45.milestoneForwardFromHistories) === null || _props$row45$mileston === void 0 ? void 0 : _props$row45$mileston.data.length) > 0 ? _vm._l(props === null || props === void 0 ? void 0 : (_props$row46 = props.row) === null || _props$row46 === void 0 ? void 0 : (_props$row46$mileston = _props$row46.milestoneForwardFromHistories) === null || _props$row46$mileston === void 0 ? void 0 : _props$row46$mileston.data, function (item, index) {
          var _item$forwardFrom, _item$forwardFrom$dat, _item$forwardFrom2, _item$forwardFrom2$da, _item$forwardFrom3, _item$forwardFrom3$da, _item$forwardFrom4, _item$forwardFrom4$da;
          return _c('div', {
            key: index,
            staticClass: "table-cell-margin"
          }, [(item === null || item === void 0 ? void 0 : item.forward_status) == 'forward_all' ? [_c('b-badge', {
            attrs: {
              "pill": "",
              "variant": "light-primary"
            }
          }, [_vm._v(_vm._s("All Forwarded From: ".concat(item === null || item === void 0 ? void 0 : (_item$forwardFrom = item.forwardFrom) === null || _item$forwardFrom === void 0 ? void 0 : (_item$forwardFrom$dat = _item$forwardFrom.data) === null || _item$forwardFrom$dat === void 0 ? void 0 : _item$forwardFrom$dat.title)))])] : _vm._e(), (item === null || item === void 0 ? void 0 : item.forward_status) == 'forward_vat' ? [_c('b-badge', {
            attrs: {
              "pill": "",
              "variant": "light-primary"
            }
          }, [_vm._v(_vm._s("VAT Forwarded From: ".concat(item === null || item === void 0 ? void 0 : (_item$forwardFrom2 = item.forwardFrom) === null || _item$forwardFrom2 === void 0 ? void 0 : (_item$forwardFrom2$da = _item$forwardFrom2.data) === null || _item$forwardFrom2$da === void 0 ? void 0 : _item$forwardFrom2$da.title)))])] : _vm._e(), (item === null || item === void 0 ? void 0 : item.forward_status) == 'forward_tax' ? [_c('b-badge', {
            attrs: {
              "pill": "",
              "variant": "light-primary"
            }
          }, [_vm._v(_vm._s("TAX Forwarded From: ".concat(item === null || item === void 0 ? void 0 : (_item$forwardFrom3 = item.forwardFrom) === null || _item$forwardFrom3 === void 0 ? void 0 : (_item$forwardFrom3$da = _item$forwardFrom3.data) === null || _item$forwardFrom3$da === void 0 ? void 0 : _item$forwardFrom3$da.title)))])] : _vm._e(), (item === null || item === void 0 ? void 0 : item.forward_status) == 'forward_amount' ? [_c('b-badge', {
            attrs: {
              "pill": "",
              "variant": "light-primary"
            }
          }, [_vm._v(_vm._s("Amount Forwarded From: ".concat(item === null || item === void 0 ? void 0 : (_item$forwardFrom4 = item.forwardFrom) === null || _item$forwardFrom4 === void 0 ? void 0 : (_item$forwardFrom4$da = _item$forwardFrom4.data) === null || _item$forwardFrom4$da === void 0 ? void 0 : _item$forwardFrom4$da.title)))])] : _vm._e()], 2);
        }) : [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-danger"
          }
        }, [_vm._v("No Action Done")])]]] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column10 = props.column) === null || _props$column10 === void 0 ? void 0 : _props$column10.field) === 'format_forward_to' ? [(props === null || props === void 0 ? void 0 : (_props$row47 = props.row) === null || _props$row47 === void 0 ? void 0 : (_props$row47$mileston = _props$row47.milestoneForwardToHistories) === null || _props$row47$mileston === void 0 ? void 0 : _props$row47$mileston.data.length) > 0 ? _vm._l(props === null || props === void 0 ? void 0 : (_props$row48 = props.row) === null || _props$row48 === void 0 ? void 0 : (_props$row48$mileston = _props$row48.milestoneForwardToHistories) === null || _props$row48$mileston === void 0 ? void 0 : _props$row48$mileston.data, function (item, index) {
          var _item$forwardTo, _item$forwardTo$data, _item$forwardTo2, _item$forwardTo2$data, _item$forwardTo3, _item$forwardTo3$data, _item$forwardTo4, _item$forwardTo4$data;
          return _c('div', {
            key: index,
            staticClass: "table-cell-margin"
          }, [(item === null || item === void 0 ? void 0 : item.forward_status) == 'forward_all' ? [_c('b-badge', {
            attrs: {
              "pill": "",
              "variant": "light-primary"
            }
          }, [_vm._v(_vm._s("All Forwarded To: ".concat(item === null || item === void 0 ? void 0 : (_item$forwardTo = item.forwardTo) === null || _item$forwardTo === void 0 ? void 0 : (_item$forwardTo$data = _item$forwardTo.data) === null || _item$forwardTo$data === void 0 ? void 0 : _item$forwardTo$data.title)))])] : _vm._e(), (item === null || item === void 0 ? void 0 : item.forward_status) == 'forward_vat' ? [_c('b-badge', {
            attrs: {
              "pill": "",
              "variant": "light-primary"
            }
          }, [_vm._v(_vm._s("VAT Forwarded To: ".concat(item === null || item === void 0 ? void 0 : (_item$forwardTo2 = item.forwardTo) === null || _item$forwardTo2 === void 0 ? void 0 : (_item$forwardTo2$data = _item$forwardTo2.data) === null || _item$forwardTo2$data === void 0 ? void 0 : _item$forwardTo2$data.title)))])] : _vm._e(), (item === null || item === void 0 ? void 0 : item.forward_status) == 'forward_tax' ? [_c('b-badge', {
            attrs: {
              "pill": "",
              "variant": "light-primary"
            }
          }, [_vm._v(_vm._s("TAX Forwarded To: ".concat(item === null || item === void 0 ? void 0 : (_item$forwardTo3 = item.forwardTo) === null || _item$forwardTo3 === void 0 ? void 0 : (_item$forwardTo3$data = _item$forwardTo3.data) === null || _item$forwardTo3$data === void 0 ? void 0 : _item$forwardTo3$data.title)))])] : _vm._e(), (item === null || item === void 0 ? void 0 : item.forward_status) == 'forward_amount' ? [_c('b-badge', {
            attrs: {
              "pill": "",
              "variant": "light-primary"
            }
          }, [_vm._v(_vm._s("Amount Forwarded To: ".concat(item === null || item === void 0 ? void 0 : (_item$forwardTo4 = item.forwardTo) === null || _item$forwardTo4 === void 0 ? void 0 : (_item$forwardTo4$data = _item$forwardTo4.data) === null || _item$forwardTo4$data === void 0 ? void 0 : _item$forwardTo4$data.title)))])] : _vm._e()], 2);
        }) : [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-danger"
          }
        }, [_vm._v("No Action Done")])]] : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.INVOICE_CREATE, _vm.permissions) ? [props !== null && props !== void 0 && (_props$row49 = props.row) !== null && _props$row49 !== void 0 && _props$row49.invoice_created_date ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "FileTextIcon"
          }
        }), _c('span', [_vm._v("Show Invoice")])], 1)] : !(props !== null && props !== void 0 && (_props$row50 = props.row) !== null && _props$row50 !== void 0 && _props$row50.invoice_created_date) && (props === null || props === void 0 ? void 0 : (_props$row51 = props.row) === null || _props$row51 === void 0 ? void 0 : _props$row51.grand_amount) > 0 ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              var _props$row52;
              return _vm.onShowInvoice((_props$row52 = props.row) === null || _props$row52 === void 0 ? void 0 : _props$row52.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "FileTextIcon"
          }
        }), _c('span', [_vm._v("Create Invoice")])], 1)] : _vm._e(), !(props !== null && props !== void 0 && (_props$row53 = props.row) !== null && _props$row53 !== void 0 && (_props$row53$mileston = _props$row53.milestoneTaskBoard) !== null && _props$row53$mileston !== void 0 && _props$row53$mileston.data) ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.createMilestoneTaskBoard(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "BriefcaseIcon"
          }
        }), _c('span', [_vm._v("Create Task Board")])], 1) : _vm._e()] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)]), _c('b-modal', {
    attrs: {
      "id": "transaction-modal",
      "centered": "",
      "title": "Milestone Transaction Details",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenTransactionModal
    }
  }, [_c('b-list-group', _vm._l(_vm.updatedAmountHistories, function (item, index) {
    var _vm$transactionHistor, _vm$transactionHistor2, _vm$transactionHistor3, _vm$transactionHistor4;
    return _c('b-list-group-item', {
      key: index
    }, [item !== null && item !== void 0 && item.is_initial ? [_c('b-badge', {
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v("Initial")]), _c('br'), _c('b-row', [_c('b-col', [_vm._v(" Amount: " + _vm._s(item === null || item === void 0 ? void 0 : item.subTotal))]), _c('b-col', [_vm._v(" VAT :" + _vm._s(item === null || item === void 0 ? void 0 : item.vat))]), _c('b-col', [_vm._v("TAX:" + _vm._s(item === null || item === void 0 ? void 0 : item.tax))]), _c('b-col', [_vm._v("Total: " + _vm._s(item === null || item === void 0 ? void 0 : item.grandTotal))])], 1)] : item !== null && item !== void 0 && item.is_invoice ? [_c('div', {
      staticClass: "mb-25"
    }, [_c('b-badge', {
      attrs: {
        "variant": "light-danger"
      }
    }, [_vm._v("Payment")]), _vm._v(" : "), _c('b-badge', {
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(_vm._s(_vm.transactionHistories[index - 1].invoice_no))])], 1), _c('br'), _c('b-row', [_c('b-col', [_vm._v(" Amount: " + _vm._s(_vm.updatedAmountHistories[index - 1].subTotal) + " - " + _vm._s(_vm.transactionHistories[index - 1].sub_total_amount) + " ")]), _c('b-col', [_vm._v(" VAT :" + _vm._s(_vm.updatedAmountHistories[index - 1].vat) + " - " + _vm._s(_vm.transactionHistories[index - 1].vat_amount) + " ")]), _c('b-col', [_vm._v("TAX: " + _vm._s(_vm.updatedAmountHistories[index - 1].tax) + " - " + _vm._s(_vm.transactionHistories[index - 1].tax_amount) + " - ")]), _c('b-col', [_vm._v("Total: " + _vm._s(item === null || item === void 0 ? void 0 : item.grandTotal))])], 1), _c('b-row', [_c('b-col', [_vm._v(" Amount: " + _vm._s(item === null || item === void 0 ? void 0 : item.subTotal))]), _c('b-col', [_vm._v(" VAT :" + _vm._s(item === null || item === void 0 ? void 0 : item.vat))]), _c('b-col', [_vm._v("TAX:" + _vm._s(item === null || item === void 0 ? void 0 : item.tax))]), _c('b-col', [_vm._v("Total: " + _vm._s(item === null || item === void 0 ? void 0 : item.grandTotal))])], 1)] : item !== null && item !== void 0 && item.is_forward_to ? [_c('div', {
      staticClass: "mb-25"
    }, [_c('b-badge', {
      attrs: {
        "variant": "light-danger"
      }
    }, [_vm._v("Forwarded To")]), _vm._v(" : "), _c('b-badge', {
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s((_vm$transactionHistor = _vm.transactionHistories[index - 1].forwardTo) === null || _vm$transactionHistor === void 0 ? void 0 : (_vm$transactionHistor2 = _vm$transactionHistor.data) === null || _vm$transactionHistor2 === void 0 ? void 0 : _vm$transactionHistor2.title))])], 1), _c('br'), _c('b-row', [_c('b-col', [_vm._v(" Amount: " + _vm._s(_vm.updatedAmountHistories[index - 1].subTotal) + " - " + _vm._s(_vm.transactionHistories[index - 1].amount))]), _c('b-col', [_vm._v(" VAT :" + _vm._s(_vm.updatedAmountHistories[index - 1].vat) + " - " + _vm._s(_vm.transactionHistories[index - 1].vat_amount))]), _c('b-col', [_vm._v("TAX:" + _vm._s(_vm.updatedAmountHistories[index - 1].tax) + " - " + _vm._s(_vm.transactionHistories[index - 1].tax_amount) + " ")]), _c('b-col', [_vm._v("Total: " + _vm._s(item === null || item === void 0 ? void 0 : item.grandTotal) + " ")])], 1), _c('b-row', [_c('b-col', [_vm._v(" Amount: " + _vm._s(item === null || item === void 0 ? void 0 : item.subTotal))]), _c('b-col', [_vm._v(" VAT :" + _vm._s(item === null || item === void 0 ? void 0 : item.vat))]), _c('b-col', [_vm._v("TAX:" + _vm._s(item === null || item === void 0 ? void 0 : item.tax))]), _c('b-col', [_vm._v("Total: " + _vm._s(item === null || item === void 0 ? void 0 : item.grandTotal))])], 1)] : item !== null && item !== void 0 && item.is_forward_from ? [_c('div', {
      staticClass: "mb-25"
    }, [_c('b-badge', {
      attrs: {
        "variant": "light-success"
      }
    }, [_vm._v(" Forwarded From")]), _vm._v(" : "), _c('b-badge', {
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s((_vm$transactionHistor3 = _vm.transactionHistories[index - 1].forwardFrom) === null || _vm$transactionHistor3 === void 0 ? void 0 : (_vm$transactionHistor4 = _vm$transactionHistor3.data) === null || _vm$transactionHistor4 === void 0 ? void 0 : _vm$transactionHistor4.title))])], 1), _c('br'), _c('b-row', [_c('b-col', [_vm._v(" Amount: " + _vm._s(_vm.updatedAmountHistories[index - 1].subTotal) + " + " + _vm._s(_vm.transactionHistories[index - 1].amount))]), _c('b-col', [_vm._v(" VAT :" + _vm._s(_vm.updatedAmountHistories[index - 1].vat) + " + " + _vm._s(_vm.transactionHistories[index - 1].vat_amount))]), _c('b-col', [_vm._v("TAX:" + _vm._s(_vm.updatedAmountHistories[index - 1].tax) + "+ " + _vm._s(_vm.transactionHistories[index - 1].tax_amount) + " ")]), _c('b-col', [_vm._v("Total: " + _vm._s(item === null || item === void 0 ? void 0 : item.grandTotal) + " ")])], 1), _c('b-row', [_c('b-col', [_vm._v(" Amount: " + _vm._s(item === null || item === void 0 ? void 0 : item.subTotal))]), _c('b-col', [_vm._v(" VAT :" + _vm._s(item === null || item === void 0 ? void 0 : item.vat))]), _c('b-col', [_vm._v("TAX:" + _vm._s(item === null || item === void 0 ? void 0 : item.tax))]), _c('b-col', [_vm._v("Total: " + _vm._s(item === null || item === void 0 ? void 0 : item.grandTotal))])], 1)] : _vm._e()], 2);
  }), 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-forward-form",
      "centered": "",
      "title": "Forward Milestone Information Form",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "xl"
    },
    on: {
      "hidden": _vm.hideForwardMilestoneModal
    }
  }, [_c('validation-observer', {
    ref: "forwardValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.forwardValidationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Project *",
      "label-for": "project"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "project",
      "vid": "project_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "project",
            "placeholder": "Choose Project",
            "options": _vm.projectIdOption,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name"
          },
          on: {
            "input": _vm.setProjectWiseMilestone
          },
          model: {
            value: _vm.projectId,
            callback: function callback($$v) {
              _vm.projectId = $$v;
            },
            expression: "projectId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Milestone *",
      "label-for": "milestone"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "milestone",
      "vid": "milestone_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "milestone",
            "placeholder": "Choose Milestone",
            "options": _vm.milestoneIdOption,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name"
          },
          on: {
            "input": _vm.setMilestoneAmount
          },
          model: {
            value: _vm.milestoneId,
            callback: function callback($$v) {
              _vm.milestoneId = $$v;
            },
            expression: "milestoneId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Amount",
      "label-for": "amount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "amount",
      "vid": "amount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Milestone Amount",
            "state": errors.length > 0 ? false : null,
            "disabled": true
          },
          model: {
            value: _vm.amount,
            callback: function callback($$v) {
              _vm.amount = $$v;
            },
            expression: "amount"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "VAT",
      "label-for": "vat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "vat",
      "vid": "vat"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Milestone VAT",
            "state": errors.length > 0 ? false : null,
            "disabled": true
          },
          model: {
            value: _vm.vat,
            callback: function callback($$v) {
              _vm.vat = $$v;
            },
            expression: "vat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "TAX",
      "label-for": "tax"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tax",
      "vid": "tax"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Milestone TAX",
            "state": errors.length > 0 ? false : null,
            "disabled": true
          },
          model: {
            value: _vm.tax,
            callback: function callback($$v) {
              _vm.tax = $$v;
            },
            expression: "tax"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Total Amount",
      "label-for": "toal_amount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "toal_amount",
      "vid": "toal_amount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "toal_amount",
            "type": "text",
            "placeholder": "Total Amount",
            "state": errors.length > 0 ? false : null,
            "disabled": true
          },
          model: {
            value: _vm.totalAmount,
            callback: function callback($$v) {
              _vm.totalAmount = $$v;
            },
            expression: "totalAmount"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-1 ml-2"
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var ariaDescribedby = _ref7.ariaDescribedby;
        return [_c('b-form-radio-group', {
          attrs: {
            "id": "radio-group-1",
            "aria-describedby": ariaDescribedby,
            "name": "radio-options"
          },
          on: {
            "change": _vm.getForwardValue
          },
          model: {
            value: _vm.selectedForwardOption,
            callback: function callback($$v) {
              _vm.selectedForwardOption = $$v;
            },
            expression: "selectedForwardOption"
          }
        }, [_c('b-row', [_c('b-col', [_c('b-form-radio', {
          attrs: {
            "value": "none"
          }
        }, [_vm._v("No Forwarding")])], 1), _c('b-col', [_c('b-form-radio', {
          attrs: {
            "value": "forward_all",
            "disabled": _vm.allForwarded
          }
        }, [_vm._v("Forward All")])], 1), _c('b-col', [_c('b-form-radio', {
          attrs: {
            "value": "forward_amount",
            "disabled": _vm.onlyAmountForwarded
          }
        }, [_vm._v("Forward Amount")])], 1), _c('b-col', [_c('b-form-radio', {
          attrs: {
            "value": "forward_vat",
            "disabled": _vm.onlyVatForwarded
          }
        }, [_vm._v("Forward VAT")])], 1), _c('b-col', [_c('b-form-radio', {
          attrs: {
            "value": "forward_tax",
            "disabled": _vm.onlyTaxForwarded
          }
        }, [_vm._v("Forward TAX")])], 1)], 1)], 1)];
      }
    }])
  })], 1)]), _vm.forwardedToNext ? [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Project",
      "label-for": "project"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "project",
      "vid": "project_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "project",
            "placeholder": "Choose Project",
            "options": _vm.projectIdOption,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "disabled": true
          },
          model: {
            value: _vm.projectId,
            callback: function callback($$v) {
              _vm.projectId = $$v;
            },
            expression: "projectId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 648360509)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Forwarding Milestone",
      "label-for": "milestone"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "forwarding milestone",
      "vid": "forward_milestone_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "milestone",
            "placeholder": "Choose Milestone",
            "options": _vm.nextMilestoneIdOption,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.nextMiletoneId,
            callback: function callback($$v) {
              _vm.nextMiletoneId = $$v;
            },
            expression: "nextMiletoneId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3509289278)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Remarks *",
      "label-for": "remarks"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "remarks",
      "vid": "remarks",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-textarea', {
          staticClass: "custom-font",
          attrs: {
            "id": "remarks",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Add Remarks"
          },
          model: {
            value: _vm.forwardRemarks,
            callback: function callback($$v) {
              _vm.forwardRemarks = $$v;
            },
            expression: "forwardRemarks"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 171585781)
  })], 1)], 1)] : _vm._e()], 2), _vm.isIncomeFormLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": !_vm.forwardedToNext
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-board-form",
      "centered": "",
      "title": "Update Milestone Info",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModalBoardModal
    }
  }, [_c('validation-observer', {
    ref: "milestoneBoardValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.milestoneBoardValidationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Board Title",
      "label-for": "board_title"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "board_title",
      "vid": "board_title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "board_title",
            "type": "text",
            "placeholder": "Board Title",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.boardTitle,
            callback: function callback($$v) {
              _vm.boardTitle = $$v;
            },
            expression: "boardTitle"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Milestone Deadline ",
      "label-for": "head-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "milestone_deadline",
      "vid": "milestone_deadline",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-datepicker', {
          staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font",
          attrs: {
            "locale": "en-US",
            "placeholder": "Milestone Deadline"
          },
          model: {
            value: _vm.newMilestoneDeadline,
            callback: function callback($$v) {
              _vm.newMilestoneDeadline = $$v;
            },
            expression: "newMilestoneDeadline"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isBoardCreateFormLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Create ")])]], 2)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }